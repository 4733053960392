import { usePathname, useSearchParams } from 'next/navigation';

import analytics from '@/segment';
import getFromLocalStorage from '@/utils/getFromLocalStorage';
import getPageNameFromPath from '@/utils/getPageNameFromPath';

const useSegmentAnalytics = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const getDefaultProperties = () => {
    const source =
      searchParams.get('src') || getFromLocalStorage('attributionSource');
    const attributionId =
      getFromLocalStorage('attributionId') || searchParams.get('gclid');
    const isInternalUser = getFromLocalStorage('internal_user');

    return {
      page_name: getPageNameFromPath(pathname),
      user_source: source || '',
      attribution_id: attributionId || '',
      url: window.location.href,
      internal_user: isInternalUser,
    };
  };

  const getDefaultContext = () => {
    const impactClickId =
      searchParams.get('irclickid') || getFromLocalStorage('impactClickId');
    if (impactClickId) {
      return {
        referrer: {
          type: 'impactRadius',
          id: impactClickId,
        },
      };
    }
    return {};
  };

  const trackEvent = (eventName: string, eventProperties?: object) => {
    const defaultProperties = getDefaultProperties();
    const context = getDefaultContext();
    const properties = { ...defaultProperties, ...eventProperties };
    return analytics.track(eventName, properties, context);
  };

  const trackPageView = () => {
    const defaultProperties = getDefaultProperties();
    const context = getDefaultContext();
    analytics.page(pathname, defaultProperties, context);
  };

  return { trackEvent, trackPageView };
};

export default useSegmentAnalytics;
